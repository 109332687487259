import React from 'react'
import styles from './BoxContacts.module.scss'
import ButtonBox from '../ButtonBox/ButtonBox'



const BoxContacts = () => {

    return (
        <div className={styles.box}>
            <div className={styles.item}>
                <p className={styles.text}>свяжитесь с&#160;нами по телефону:</p>
                <a className={styles.phone}  href="tel:+74954779690" target='_blank' rel='noopener noreferrer'>+7 (495) 477-96-90</a>
            </div>
            <div className={styles.item}>
                <p className={styles.text}>напишите нам в месседжерах:</p>
                <ButtonBox/>
            </div>
        </div>
    )
}

export default BoxContacts