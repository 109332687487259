import React from 'react'
import styles from './Director.module.scss'
import image from '../../image/Director/founder.webp'



const Director = () => {

    return (
        <section 
            className={styles.main}
            >
            <div className={styles.box}>
                <div className={styles.info}>
                    <p className={styles.director}>РУКОВОДИТЕЛЬ КОМПАНИИ</p>
                    <h3 className={styles.title}>Владислав Благодетелев</h3>
                    <p className={styles.subtitle}>Дипломированный юрист, судебный эксперт по работе с застройщиками с&#160;2014&#160;года.</p>
                    <p className={styles.text}>«Наша главная задача — максимально упростить для клиента его спор с застройщиком. А именно: взять на себя все сложности, связанные с подачей претензии, ведением судебных заседаний и т. д.</p>
                    <p className={styles.text}>Мы не делаю акцент на решении суда или исполнительном листе. <span>Работаем до поступления денежных средств от застройщика на счет клиента».</span></p>
                    <div className={styles.data}>
                        <div className={styles.data_box}>
                            <p className={styles.data_number}>610</p>
                            <p className={styles.data_text}>млн рублей взыскано нами с застройщиков в пользу дольщиков</p>
                        </div>
                        <div className={styles.data_box}>
                            <p className={styles.data_number}>2190</p>
                            <p className={styles.data_text}>выиграно судебных дел</p>
                        </div>
                    </div>
                </div>
                <div className={styles.image_box}>
                    <img className={styles.image} src={image} alt="Владислав Благодетелев"/>
                    <div className={styles.image_info}>
                        <p className={styles.image_info_title}>Владислав Благодетелев</p>
                        <p className={styles.image_info_text}>Pуководитель группы компаний <span>Партнеры Инвест</span></p>
                    </div>
                </div>
                
            </div>
        </section>
    )
}

export default Director