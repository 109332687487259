import React, {useState, useCallback} from 'react'
import styles from './FiveSteps.module.scss'
import { motion } from "framer-motion"
import ButtonBox from '../ButtonBox/ButtonBox'
import image from '../../image/FiveSteps/pipl.webp'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const FiveSteps = ({isOpen}) => {

    const [isBackground, setIsBackground] = useState(1)

    const handleBackgroundOne = useCallback(() => {
        setIsBackground(1)
    }, []);

    const handleBackgroundTwo = useCallback(() => {
        setIsBackground(2)
    }, []);


    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <div className={styles.box}>
                <div className={styles.info}>
                    <motion.h2 className={styles.title}  variants={titleAnimation}>За <span>5 простых шагов</span> получите свои денежные средства от застройщика</motion.h2>
                    <motion.p className={styles.text} variants={titleAnimation}>Выберите удобный формат взаимодействия</motion.p>
                    <motion.div className={styles.box_button} variants={titleAnimation}>
                        <button className={`${isBackground === 1 ? styles.button_menu : styles.button_menu_active}`} type='button' onClick={handleBackgroundOne}>личная встреча</button>
                        <button className={`${isBackground === 2 ? styles.button_menu : styles.button_menu_active}`} type='button' onClick={handleBackgroundTwo}>дистанционно</button>
                    </motion.div>
                    <motion.div className={styles.box_image} variants={imageAnimation}>
                        <img className={styles.image} src={image} alt = 'документы'/>
                    </motion.div>
                </div>
                <ul className={`${isBackground === 1 ? styles.list : styles.list_none}`}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.circle}>
                            <div className={styles.circle_one}>
                                <p className={styles.number}>01</p>
                            </div>
                        </div>
                        <div className={styles.item_box}>
                            <h3 className={styles.item_title}>ЗАЯВКА</h3>
                            <div className={styles.item_box_contacts}>
                                <p className={styles.item_text}>Позвоните нам:</p>
                                    <a className={styles.link_phone}  href="tel:+74954779690" target='_blank' rel='noopener noreferrer'>+7 (495) 477-96-90</a>
                            </div>
                            <div className={styles.item_box_contacts}>
                                <p className={styles.item_text}>Можете написать в один из мессенджеров:</p>
                                <ButtonBox/>
                            </div>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.circle}>
                            <div className={styles.circle_one}>
                                <p className={styles.number}>02</p>
                            </div>
                        </div>
                        <div className={styles.item_box}>
                            <h3 className={styles.item_title}>БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ</h3>
                            <p className={styles.item_text}>Проводится личная встреча в любом удобном для вас месте. <span>Изучаем вашу ситуацию, документы, застройщика и вникаем в суть дела.</span></p>
                            <button className={styles.button} type='button' onClick={isOpen}>Записаться на личную встречу</button>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.circle}>
                            <div className={styles.circle_one}>
                                <p className={styles.number}>03</p>
                            </div>
                        </div>
                        <div className={styles.item_box}>
                            <h3 className={styles.item_title}>ДОГОВОР</h3>
                            <p className={styles.item_text}>Заключаем договор, где указываем сроки и условия работы (фиксированная стоимость или за процент от выигранной суммы).</p>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.circle}>
                            <div className={styles.circle_one}>
                                <p className={styles.number}>04</p>
                            </div>
                        </div>
                        <div className={styles.item_box}>
                            <h3 className={styles.item_title}>СУДЕБНЫЕ ПРОЦЕССЫ</h3>
                            <p className={styles.item_text}>Судебные споры с вашим застройщиком от 3 до 6 месяцев. <span>Вынесение решения суда в вашу пользу.</span></p>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item_last}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.circle}>
                            <div className={styles.circle_one}>
                                <p className={styles.number}>05</p>
                            </div>
                        </div>
                        <div className={styles.item_box}>
                            <h3 className={styles.item_title}>ПОЛУЧЕНИЕ ДЕНЕГ</h3>
                            <p className={styles.item_text}><span>Поступление денежных средств от застройщика на ваш счет</span> по исполнительному листу.</p>
                        </div>
                    </motion.li>
                </ul>
                <ul className={`${isBackground === 2 ? styles.list : styles.list_none}`}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.circle}>
                            <div className={styles.circle_one}>
                                <p className={styles.number}>01</p>
                            </div>
                        </div>
                        <div className={styles.item_box}>
                            <h3 className={styles.item_title}>ЗАЯВКА</h3>
                            <div className={styles.item_box_contacts}>
                                <p className={styles.item_text}>Позвоните нам:</p>
                                    <a className={styles.link_phone}  href="tel:+74954779690" target='_blank' rel='noopener noreferrer'>+7 (495) 477-96-90</a>
                            </div>
                            <div className={styles.item_box_contacts}>
                                <p className={styles.item_text}>Можете написать в один из мессенджеров:</p>
                                <ButtonBox/>
                            </div>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.circle}>
                            <div className={styles.circle_one}>
                                <p className={styles.number}>02</p>
                            </div>
                        </div>
                        <div className={styles.item_box}>
                            <h3 className={styles.item_title}>БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ</h3>
                            <p className={styles.item_text}>Проводится онлайн консультация (Zoom, Skype, Whatsapp) в любое удобное для вас время. <span>Изучаем вашу ситуацию, документы, застройщика и вникаем в суть дела.</span></p>
                            <button className={styles.button} type='button' onClick={isOpen}>Записаться на онлайн-консультацию</button>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.circle}>
                            <div className={styles.circle_one}>
                                <p className={styles.number}>03</p>
                            </div>
                        </div>
                        <div className={styles.item_box}>
                            <h3 className={styles.item_title}>ДОГОВОР</h3>
                            <p className={styles.item_text}>Дистационно заключается договор, где указываем сроки и условия работы. (фиксированная стоимость или за процент от выигранной суммы).</p>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.circle}>
                            <div className={styles.circle_one}>
                                <p className={styles.number}>04</p>
                            </div>
                        </div>
                        <div className={styles.item_box}>
                            <h3 className={styles.item_title}>СУДЕБНЫЕ ПРОЦЕССЫ</h3>
                            <p className={styles.item_text}>Судебные споры с вашим застройщиком от 3 до 6 месяцев. <span>Вынесение решения суда в вашу пользу.</span></p>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item_last}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.circle}>
                            <div className={styles.circle_one}>
                                <p className={styles.number}>05</p>
                            </div>
                        </div>
                        <div className={styles.item_box}>
                            <h3 className={styles.item_title}>ПОЛУЧЕНИЕ ДЕНЕГ</h3>
                            <p className={styles.item_text}><span>Поступление денежных средств от застройщика на ваш счет</span> по исполнительному листу.</p>
                        </div>
                    </motion.li>
                </ul>
            </div>
        </motion.section>
    )
}

export default FiveSteps