import React, { useCallback, useState, useEffect, useRef } from 'react'
import emailjs from '@emailjs/browser'
import { createPortal } from "react-dom"
import styles from './ModalForm.module.scss'
import {ModalOverlay} from '../ModalOverlay/ModalOverlay'
import ButtonBox from '../ButtonBox/ButtonBox'
import { FormValidation } from '../../hooks/FormValidation'
import InputMask from "react-input-mask"


export const ModalForm = ({onClose, popupOpen}) => {

    const modalRoot = document.getElementById("modals");
    
    const handleForm = FormValidation()
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const form = useRef()

    useEffect(() => {
        handleForm.isValid ? setButtonDisabled(false) : setButtonDisabled(true)
    }, [handleForm.isValid])

    const sendData = useCallback((evt) => {
        evt.preventDefault();
            emailjs.sendForm('service_72ak8lu', 'template_ka8wrr6', form.current, 'uUK6nnuVy0jXrFg28')
            .then((result) => {
            }, (error) => {
                console.log(error.text);
            }); 
        onClose()
        handleForm.resetForm()
        popupOpen()
    }, [handleForm, onClose, popupOpen] )

    useEffect(() => {
        const handleEscClose = (evt) =>{
            if (evt.key ==='Escape')  onClose()
        }
        document.addEventListener('keydown', handleEscClose)
        return () => document.removeEventListener('keydown', handleEscClose)
    }, [onClose])

    return createPortal (
        <ModalOverlay onClick={onClose}>
            <div className={styles.modal} onClick={evt => evt.stopPropagation()}>
                <button data-test="close-button" className={styles.close_icon} type="button" aria-label="закрыть" onClick={onClose}/>
                <div className={styles.overlay}>
                    <div className={styles.info}>
                        <h2 className={styles.title}>Получите бесплатную консультацию юриста</h2>
                        <p className={styles.subtitle}>Оценим перспективы вашего дела на консультации в течение 1 часа</p>
                        <p className={styles.text}>В ХОДЕ КОНСУЛЬТАЦИИ ВЫ ПОЛУЧИТЕ:</p>
                        <ul className={styles.list}>
                            <li className={styles.item}>
                                <div className={styles.check_box}>
                                    <p className={styles.check}>&#10003;</p>
                                </div>
                                <p className={styles.item_text}>Анализ вашей ситуации и документов для оценки перспективы вашего дела.</p>
                            </li>
                            <li className={styles.item}>
                                <div className={styles.check_box}>
                                    <p className={styles.check}>&#10003;</p>
                                </div>
                                <p className={styles.item_text}>Расчёт неустойки по текущему курсу ЦБ, которую вы сможете взыскать.</p>
                            </li>
                            <li className={styles.item}>
                                <div className={styles.check_box}>
                                    <p className={styles.check}>&#10003;</p>
                                </div>
                                <p className={styles.item_text}>Отчет о платежеспособности и финансовой устойчивости застройщика. Вы поймете, как действовать в вашей ситуации.</p>
                            </li>
                            <li className={styles.item}>
                                <div className={styles.check_box}>
                                    <p className={styles.check}>&#10003;</p>
                                </div>
                                <p className={styles.item_text}>Расчет стоимости дела под ключ — от подачи претензии до появления денег у вас на счету.</p>
                            </li>
                            <li className={styles.item}>
                                <div className={styles.check_box}>
                                    <p className={styles.check}>&#10003;</p>
                                </div>
                                <p className={styles.item_text}>Ответы на все ваши вопросы.</p>
                            </li>
                        </ul>
                        <div className={styles.box_min}>
                        <form className={styles.form} onSubmit={sendData} ref={form}>
                            <div className={styles.box_input}>
                                <label className={styles.label}>      
                                    <InputMask
                                        className={`${styles.input} ${handleForm.errors.phone && styles.wrong}`}
                                        type='tel' 
                                        name='phone'
                                        placeholder="Введите телефон"
                                        mask={'+7\\(999) 999-99-99'}
                                        value={handleForm.values.this}
                                        onChange={handleForm.handleChange}  
                                        pattern='\+7\s?[\(][0-9]{3}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}'
                                        required/>
                                </label>
                            </div>            
                            <span className={styles.input_error}>{handleForm.errors.phone}</span>
                                    <button className={`${styles.button_form} ${buttonDisabled && styles.button_disabled}`}type='submit'><span>ПОЛУЧИТЬ БЕСПЛАТНУЮ КОНСУЛЬТАЦИЮ</span></button>
                        </form>
                        <p className={styles.data}>Нажимая кнопку, вы даете согласие на обработку персональных данных</p>
                        <div className={styles.box_social}>
                            <p className={styles.text_social}>Или свяжитесь с нами в месседжерах:</p>
                            <ButtonBox/>
                        </div>
                </div>
                    </div>
                    
                <div className={styles.box}>
                        <form className={styles.form} onSubmit={sendData} ref={form}>
                            <span className={styles.input_error}>{handleForm.errors.name}</span>
                            <div className={styles.box_input}>
                                <label className={styles.label}>      
                                    <InputMask
                                        className={`${styles.input} ${handleForm.errors.phone && styles.wrong}`}
                                        type='tel' 
                                        name='phone'
                                        placeholder="Введите телефон"
                                        mask={'+7\\(999) 999-99-99'}
                                        value={handleForm.values.this}
                                        onChange={handleForm.handleChange}  
                                        pattern='\+7\s?[\(][0-9]{3}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}'
                                        required/>
                                </label>
                            </div>            
                            <span className={styles.input_error}>{handleForm.errors.phone}</span>
                                    <button className={`${styles.button_form} ${buttonDisabled && styles.button_disabled}`}type='submit'><span>ПОЛУЧИТЬ БЕСПЛАТНУЮ КОНСУЛЬТАЦИЮ</span></button>
                        </form>
                        <p className={styles.data}>Нажимая кнопку, вы даете согласие на обработку персональных данных</p>
                        <div className={styles.box_social}>
                            <p className={styles.text_social}>Или свяжитесь с нами в месседжерах:</p>
                            <ButtonBox/>
                        </div>
                </div>
                </div>
            </div>
        </ModalOverlay>
        ,modalRoot
    );
};