import React from 'react'
import styles from './Footer.module.scss'
import logo from '../../image/Header/logo.png'
import ButtonBox from '../ButtonBox/ButtonBox'

const Footer = () => {

    return (
        <footer className={styles.main} id='kontakty'>
            <div className={styles.container}>
                <div className={styles.box}>
                    <img className={styles.logo} src={logo} alt = 'логотип'/>
                    <div className={styles.box_mail}>
                        <a className={styles.link_mail}  href="tel:+74954779690" target='_blank' rel='noopener noreferrer'>+7 495 <span>4779690</span></a>
                    </div>
                    <ButtonBox/>
                </div>
                <div className={styles.box}>
                    <p className={styles.text}>Копирование материалов сайта запрещено.</p>
                    <p className={styles.text}>&#169; 2024 Все права защищены.</p>
                    <a className={styles.link_developer} target='_blank' rel='noopener noreferrer' href='https://tihonov-studio.ru/' aria-label='сайт тихонова дмитрия'>
                        <p className={styles.text}>Разработано<span> TD</span></p>
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer