import React from 'react'
import styles from './InitialPage.module.scss'
import right from '../../image/InitialPage/require-right.png'
import left from '../../image/InitialPage/require-left.png'
import documents from '../../image/InitialPage/require-title.png'


const InitialPage = ({isOpen}) => {
    return (
        <section className={styles.main} id='initial'>
            <img className={styles.image_left} src={left} alt='кустик'/>
            <img className={styles.image_right} src={right} alt='кустик'/>
            <div className={styles.container}>
                <p className={styles.text}>Застройщик нарушил сроки передачи или сдал квартиру с недостатками?</p>
                <h1 className={styles.title}><span>Взыщем с застройщика</span> конпенсацию за ремонт, штраф, неустойку и прочие расходы</h1>
                <div className={styles.box}>
                    <img className={styles.documents} src={documents} alt='документы'/>
                    <p className={styles.subtitle}>Добьемся взыскания с&#160;гарантией по договору</p>
                </div>
                <ul className={styles.list}>
                    <li className={styles.item}>
                        <p className={styles.check}>&#10003;</p>
                        <p className={styles.item_text}>С 2016 года взыскали с застройщиков <span>более 610 млн рублей</span></p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.check}>&#10003;</p>
                        <p className={styles.item_text}>Работаем за фиксированную стоимость или за процент от выигранной суммы (без предоплаты)</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.check}>&#10003;</p>
                        <p className={styles.item_text}>Не проиграли ни одного дела по ДДУ за всю нашу практику</p>
                    </li>
                </ul>
                <p className={styles.text_gray}><span>Получите бесплатную развернутую консультацию</span> юриста по вашему вопросу в течение 10 минут</p>
                <div className={styles.box_button}>
                    <button className={styles.button} type='button' onClick={isOpen}>получить бесплатную консультацию</button>
                </div>
            </div>
        </section>
    )
}

export default InitialPage