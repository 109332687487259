import React from 'react'
import styles from './Confidence.module.scss'
import { motion } from "framer-motion"


const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}



const Confidence = () => {

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}>Почему <span>вы можете быть уверены</span> в том, что мы выиграем ваше дело с застройщиком</motion.h2>
                <motion.ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>01</p>
                            <p className={styles.item_title}>Работаем только по делам и спорам с застройщиками</p>
                        </div>
                        <p className={styles.item_text}>5 лет узко специализируемся на этом поле деятельности. <span>Знаем все нюансы и тонкости работы с застройщиками.</span></p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>02</p>
                            <p className={styles.item_title}>Знаем практику по судам Москвы&#160;и&#160;МО</p>
                        </div>
                        <p className={styles.item_text}><span>Все суды Москвы и МО изъезжены нами вдоль и поперек.</span> Мы знаем в какой суд лучше обратиться, для получения наилучшего результата.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>03</p>
                            <p className={styles.item_title}>Заинтересованы в вашем выигрыше</p>
                        </div>
                        <p className={styles.item_text}>Так как <span>наша оплата — это процент от полученной вами суммы.</span> Работаем до появления денег у вас на счету.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>04</p>
                            <p className={styles.item_title}>Выигрываем все дела, за которые беремся</p>
                        </div>
                        <p className={styles.item_text}>Пройдя через сотни судебных заседаний, мы уверены, что сможем выиграть ваше дело. <span>Не проиграли ни одного суда с застройщиками.</span></p>
                    </motion.li>
                </motion.ul>
            </div>
        </motion.section>
    )
}

export default Confidence