import React, {useCallback, useState} from 'react'
import app from './App.module.scss'
import Main from '../../pages/Main/Main'
import Header  from '../Header/Header'
import Footer  from '../Footer/Footer'
import ModalArticle from '../ModalArticle/ModalArticle'



import { ModalForm } from '../ModalForm/ModalForm'
import { Modal } from '../Modal/Modal'
import UpButton from '../UpButton/UpButton'

function App() {

  const [isModalArticleOpen, setIsModalArticleOpen] = useState(false)
  const [isPopupFormOpen, setIsPopupFormOpen] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const vh = window.innerHeight / 100;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  const handleFormOpen = useCallback(() => {
    setIsPopupFormOpen(true)
  }, []);

  const handleFormClose = useCallback(() => {
    setIsPopupFormOpen(false)
  }, []);

  const handleOpen = useCallback(() => {
    setIsPopupOpen(true)
  }, []);

  const handleClose = useCallback(() => {
    setIsPopupOpen(false)
  }, []);


  const handleModalArticleOpen = useCallback(() => {
    setIsModalArticleOpen(true)
  }, []);

  const handleModalArticleClose = useCallback(() => {
    setIsModalArticleOpen(false)
  }, []);



  return (
    <div className={app.page}>
      <Header
      />
      <Main
        isOpen={handleFormOpen}
        popupOpen={handleOpen}
        ModalArticleOpen={handleModalArticleOpen}
      />
      {isModalArticleOpen && 
      <ModalArticle
          ModalArticleClose={handleModalArticleClose}
      ></ModalArticle>}


      {isPopupFormOpen && 
      <ModalForm
        onClose={handleFormClose}
        popupOpen={handleOpen}
      ></ModalForm>}
      {isPopupOpen && 
      <Modal
        onClose={handleClose}
      />}
      <Footer/>
      <UpButton/>
    </div>
  );
}

export default App;
