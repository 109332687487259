import React from 'react'
import styles from './FreeConsultation.module.scss'
import BoxContacts from '../BoxContacts/BoxContacts'
import BoxFormConsultation from '../BoxFormConsultation/BoxFormConsultation'



const FreeConsultation = () => {

    return (
        <section 
            className={styles.main}
            >
            <div className={styles.overlay}>
            <div className={styles.box}>
                <div className={styles.info}>
                    <h3 className={styles.title}>Для понимания всех нюансов и определения перспективы по вашему делу</h3>
                    <p className={styles.subtitle}>Предлагаем начать работу с бесплатной консультации, на которой сможем на 100% погрузиться в ваше дело.</p>
                    <p className={styles.text_gray}>В ХОДЕ КОНСУЛЬТАЦИИ ВЫ ПОЛУЧИТЕ:</p>
                    <ul  className={styles.list}>
                        <li  className={styles.item}>
                            <div className={styles.check_box}>
                                <p className={styles.check}>&#10003;</p>
                            </div>
                            <p className={styles.item_text}>Анализ вашей ситуации и документов для оценки перспективы вашего дела.</p>
                        </li>
                        <li  className={styles.item}>
                            <div className={styles.check_box}>
                                <p className={styles.check}>&#10003;</p>
                            </div>
                            <p className={styles.item_text}>Расчёт неустойки по текущему курсу ЦБ, которую вы сможете взыскать.</p>
                        </li>
                        <li  className={styles.item}>
                            <div className={styles.check_box}>
                                <p className={styles.check}>&#10003;</p>
                            </div>
                            <p className={styles.item_text}>Отчет о платежеспособности и финансовой устойчивости застройщика. Вы поймете, как действовать в вашей ситуации.</p>
                        </li>
                        <li  className={styles.item}>
                            <div className={styles.check_box}>
                                <p className={styles.check}>&#10003;</p>
                            </div>
                            <p className={styles.item_text}>Расчет стоимости дела под ключ — от подачи претензии до появления денег у вас на счету.</p>
                        </li>
                        <li  className={styles.item}>
                            <div className={styles.check_box}>
                                <p className={styles.check}>&#10003;</p>
                            </div>
                            <p className={styles.item_text}>Ответы на все ваши вопросы.</p>
                        </li>
                    </ul>
                </div>
                <div className={styles.box_form}>
                    <BoxFormConsultation/>
                    <BoxContacts/>
                </div>
            </div>
            </div>
            <div className={styles.box_form_min}>
                    <BoxFormConsultation/>
                    <BoxContacts/>
            </div>
        </section>
    )
}

export default FreeConsultation