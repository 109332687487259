import React from 'react'
import styles from './SubmitRequest.module.scss'
import BoxContacts from '../BoxContacts/BoxContacts'
import BoxForm from '../BoxForm/BoxForm'



const SubmitRequest = () => {

    return (
        <section 
            className={styles.main}
            >
            <div className={styles.overlay}>
            <div className={styles.box}>
                <div className={styles.info}>
                    <h3 className={styles.title}>Успейте подать письменную претензию к застройщику, пока он финансово стабилен</h3>
                    <p className={styles.subtitle}><span className={styles.subtitle_white}>Почему это важно?</span> Если затянуть с подачей претензии, <span className={styles.subtitle_red}>успешность взыскания неустойки снижается на 70%</span></p>
                    <p className={styles.text_gray}>ЧТО ВЫ ПОЛУЧИТЕ:</p>
                    <ul  className={styles.list}>
                        <li  className={styles.item}>
                            <div className={styles.check_box}>
                                <p className={styles.check}>&#10003;</p>
                            </div>
                            <p className={styles.item_text}>Анализ вашей ситуации и документов для оценки перспективы вашего дела.</p>
                        </li>
                        <li  className={styles.item}>
                            <div className={styles.check_box}>
                                <p className={styles.check}>&#10003;</p>
                            </div>
                            <p className={styles.item_text}>Готовую претензию застройщику — мы ее составим, отправим и будем отслеживать ее исполнение.</p>
                        </li>
                        <li  className={styles.item}>
                            <div className={styles.check_box}>
                                <p className={styles.check}>&#10003;</p>
                            </div>
                            <p className={styles.item_text}>Почтовую отправку претензии застройщику за наш счет.</p>
                        </li>
                        <li  className={styles.item}>
                            <div className={styles.check_box}>
                                <p className={styles.check}>&#10003;</p>
                            </div>
                            <p className={styles.item_text}>Расчёт неустойки по текущему курсу ЦБ, которую вы сможете взыскать</p>
                        </li>
                    </ul>
                    <p className={styles.text}>Вам нужно будет только подписать договор и выдать нам доверенность. Все остальное сделаем мы за вас. За 2 дня составим и отправим претензию.</p>
                    <div className={styles.box_percent}>
                        <p className={styles.percent}>5%</p>
                        <p className={styles.percent_text}>Клиентов получают полную неустойку сразу после подачи претензии в адрес застройщика.</p>
                    </div>
                </div>
                <div className={styles.box_form}>
                    <div className={styles.form_box_text}>
                        <p className={styles.form_text}>Работали с <span>89 застройщиками и подали свыше 1300 претензий,</span> поэтому знаем, как написать жалобу, чтобы ее приняли с первого раза.</p>
                    </div>
                    <BoxForm/>
                    <BoxContacts/>
                </div>
            </div>
            </div>
            <div className={styles.box_form_min}>
                    <div className={styles.form_box_text}>
                        <p className={styles.form_text}>Работали с <span>89 застройщиками и подали свыше 1300 претензий,</span> поэтому знаем, как написать жалобу, чтобы ее приняли с первого раза.</p>
                    </div>
                    <BoxForm/>
                    <BoxContacts/>
            </div>
        </section>
    )
}

export default SubmitRequest