import React, { useCallback, useState, useEffect, useRef } from 'react'
import styles from './BoxFormConsultation.module.scss'
import emailjs from '@emailjs/browser'
import { FormValidation } from '../../hooks/FormValidation'
import InputMask from "react-input-mask"



const BoxFormConsultation = () => {

    const handleForm = FormValidation()
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const form = useRef()

    useEffect(() => {
        handleForm.isValid ? setButtonDisabled(false) : setButtonDisabled(true)
    }, [handleForm.isValid])

    const sendData = useCallback((evt) => {
        evt.preventDefault();
            emailjs.sendForm('service_72ak8lu', 'template_ka8wrr6', form.current, 'uUK6nnuVy0jXrFg28')
            .then((result) => {
            }, (error) => {
                console.log(error.text);
            }); 
            handleForm.resetForm()
    }, [handleForm] )

    return (
        <div className={styles.box}>
            <h3 className={styles.title}>В результате консультации</h3>
            <p className={styles.text}>У вас сложится четкое понимание перспективы по вашему делу. Консультация вас ни к чему не обязывает</p>
            <form className={styles.form} onSubmit={sendData} ref={form}>
                <div className={styles.box_input}>
                    <label className={styles.label}>      
                    <InputMask
                        className={`${styles.input} ${handleForm.errors.phone && styles.wrong}`}
                        type='tel' 
                        name='phone'
                        placeholder="Введите телефон"
                        mask={'+7\\(999) 999-99-99'}
                        value={handleForm.values.this}
                        onChange={handleForm.handleChange}  
                        pattern='\+7\s?[\(][0-9]{3}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}'
                        required/>
                    </label>
                </div>            
                <button className={`${styles.button_form} ${buttonDisabled && styles.button_disabled}`}type='submit'><span>получить консультацию</span></button>
                </form>
                <p className={styles.text_button}>Нажимая кнопку, вы даете согласие на обработку персональных данных</p>
        </div>
    )
}

export default BoxFormConsultation