import React, {useEffect} from 'react'
import { createPortal } from "react-dom"
import styles from './ModalArticle.module.scss'
import {ModalOverlay} from '../ModalOverlay/ModalOverlay'


const ModalArticle = ({ModalArticleClose}) => {

    const modalRoot = document.getElementById("modals");


    useEffect(() => {
        const handleEscClose = (evt) =>{
            if (evt.key ==='Escape')  ModalArticleClose()
        }
        document.addEventListener('keydown', handleEscClose)
        return () => document.removeEventListener('keydown', handleEscClose)
    }, [ModalArticleClose])

    return createPortal (
        <ModalOverlay onClick={ModalArticleClose}>
            <div className={styles.modal} onClick={evt => evt.stopPropagation()}>
                <h3 className={styles.title}>ГПК РФ Статья 61. Основания для освобождения от доказывания</h3>
                <p className={styles.text}>1. Обстоятельства, признанные судом общеизвестными, не нуждаются в доказывании.</p>
                <p className={styles.text}>2. Обстоятельства, установленные вступившим в законную силу судебным постановлением по ранее рассмотренному делу, обязательны для суда. Указанные обстоятельства не доказываются вновь и не подлежат оспариванию при рассмотрении другого дела, в котором участвуют те же лица, а также в случаях, предусмотренных настоящим Кодексом.</p>
                <p className={styles.text}>3. При рассмотрении гражданского дела обстоятельства, установленные вступившим в законную силу решением арбитражного суда, не должны доказываться и не могут оспариваться лицами, если они участвовали в деле, которое было разрешено арбитражным судом.</p>
                <p className={styles.text}>4. Вступившие в законную силу приговор суда по уголовному делу, иные постановления суда по этому делу и постановления суда по делу об административном правонарушении обязательны для суда, рассматривающего дело о гражданско-правовых последствиях действий лица, в отношении которого они вынесены, по вопросам, имели ли место эти действия и совершены ли они данным лицом.</p>
                <p className={styles.text}>5. Обстоятельства, подтвержденные нотариусом при совершении нотариального действия, не требуют доказывания, если подлинность нотариально оформленного документа не опровергнута в порядке, установленном статьей 186 настоящего Кодекса, или не установлено существенное нарушение порядка совершения нотариального действия.</p>  
            </div>
        </ModalOverlay>
        ,modalRoot
    );
};

export default ModalArticle