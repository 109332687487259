import React, {useEffect} from 'react'
import { createPortal } from "react-dom"
import modal from './Modal.module.scss'
import {ModalOverlay} from '../ModalOverlay/ModalOverlay'
import ButtonBox from '../ButtonBox/ButtonBox'

export const Modal = ({onClose}) => {

    const modalRoot = document.getElementById("modals");

    useEffect(() => {
        const handleEscClose = (evt) =>{
            if (evt.key ==='Escape')  onClose()
        }
        document.addEventListener('keydown', handleEscClose)
        return () => document.removeEventListener('keydown', handleEscClose)
    }, [onClose])

    return createPortal (
        <ModalOverlay onClick={onClose}>
            <div className={modal.modal} onClick={evt => evt.stopPropagation()}>
                <div className={modal.overlay}>
                    <h2 className={modal.title}>Спасибо за обращение</h2>
                    <p className={modal.text}>Наш юрист свяжется с вами в ближайшее время</p>
                    <button className={modal.button} type='button' onClick={onClose}>Вернуться на сайт</button>
                    <div className={modal.box_social}>
                        <p className={modal.text_social}>Или свяжитесь с нами в месседжерах:</p>
                        <ButtonBox/>
                    </div> 
                </div>
            </div>
        </ModalOverlay>
        ,modalRoot
    );
};