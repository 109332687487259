import React from 'react'
import styles from './Main.module.scss'
import InitialPage from '../../components/initialPage/InitialPage'
import Confidence from '../../components/Confidence/Confidence'
import SubmitRequest from '../../components/SubmitRequest/SubmitRequest'
import Director from '../../components/Director/Director'
import Informing from '../../components/Informing/Informing'
import FiveSteps from '../../components/FiveSteps/FiveSteps'
import Map from '../../components/Map/Map'
import FreeConsultation from '../../components/FreeConsultation/FreeConsultation'

const Main = ({isOpen, ModalArticleOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage
                isOpen={isOpen}
            />
            <Confidence/>
            <SubmitRequest/>
            <Director/>
            <FreeConsultation/>
            <Informing/>
            <FiveSteps
                isOpen={isOpen}
            />
            <Map/>
        </section>
    )
}

export default Main