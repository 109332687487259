import React from 'react'
import styles from './Informing.module.scss'
import { motion } from "framer-motion"
import email from '../../image/Footer/email-icon.svg'
import phone from '../../image/Footer/phone.svg'
import documents from '../../image/Footer/documents.svg'
import recording from '../../image/Footer/recording.svg'
import chat from '../../image/Footer/chat.svg'
import right from '../../image/Informing/img.png'
import image from '../../image/Informing/phone_2.webp'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}

const Informing = () => {

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <motion.div  className={styles.box_title} variants={titleAnimation}>
                <h2 className={styles.title}  variants={titleAnimation}>Обратившись к нам, вы получаете <span>детальное информирование</span> о ходе дела и ускоренное оформление документов</h2>
            </motion.div>
            <div className={styles.box}>
                    <ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.check_box}>
                            <img className={styles.icon_contacts} src={email} alt = 'icon'/>
                        </div>
                        <p className={styles.item_text}>SMS-информирование о ходе вашего дела</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.check_box}>
                            <img className={styles.icon_contacts} src={chat} alt = 'icon'/>
                        </div>
                        <p className={styles.item_text}>Чат с нашими юристами в WhatsApp или Telegram, где вы можете задать любые вопросы</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.check_box}>
                            <img className={styles.icon_contacts} src={documents} alt = 'icon'/>
                        </div>
                        <p className={styles.item_text}>Свой нотариус в центре города Москвы. Это позволит быстро и без очередей оформить доверенность</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.check_box}>
                            <img className={styles.icon_contacts} src={phone} alt = 'icon'/>
                        </div>
                        <p className={styles.item_text}>В любой момент можете написать или позвонить и узнать о ходе своего дела</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <div className={styles.check_box}>
                            <img className={styles.icon_contacts} src={recording} alt = 'icon'/>
                        </div>
                        <p className={styles.item_text}>Аудиозаписи каждого судебного процесса</p>
                    </motion.li>
                </ul>
                <div className={styles.box_image}>
                    <img className={styles.image} src={image} alt = 'телефон'/>
                    <img className={styles.image_right} src={right} alt='кустик'/>
                </div>
            </div>
        </motion.section>
    )
}

export default Informing